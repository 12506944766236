import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import SelectFilter from '../../../../commonComponents/lists/filters/SelectFilter';
import WarningCard from '../../../../commonComponents/cards/WarningCard';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';

function TabCountries(props) {
  const { t, countries, organizationCountries, onChange } = props;

  return (
    <div id="tabAvailableCountries">
      <input
        type="hidden"
        id="organizationAvailableCountries"
        name="organizationAvailableCountries"
        value={JSON.stringify(organizationCountries)}
      />
      <section>
        <h4>{t('countries')}</h4>
        <SelectFilter
          id="countiresSelector"
          placeholder={t('countries')}
          label={t('settings.configurations.countries.available.label')}
          hint={t('settings.configurations.countries.available.hint')}
          onChange={onChange}
          initialValue={organizationCountries}
          multiple
          items={countries.map(c => ({
            id: c.code,
            text: `${c.flagEmoji} ${c.name}`,
          }))}
        />
        <WarningCard wrapperClass="warning-countries">
          <span>
            {t('settings.configurations.countries.available.warning')}
          </span>
        </WarningCard>
      </section>
    </div>
  );
}

TabCountries.propTypes = {
  t: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  organizationCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
TabCountries.defaultProps = {};

export default translate(TabCountries);
