import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import TabCountries from '../../sections/settings/configurations/general/TabCountries';
import CountryProp from '../../commonComponents/forms/Address/CountryProp';
import organizationProp from '../../commonComponents/propTypes/organizationProp';
import Notifications from '../../services/notifications';

const TabCountriesWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const { t, organization, countries, error } = props;
  const { countries: countriesInOrganization } = organization || {};

  // since we are injecting in ejs, the react component don't have the "normal behavior"
  // ref to make sure we only show error first time it shows
  const isMountedRef = useRef(false);

  const { details: countriesErrors } = error || {};

  let organizationCountries = countriesInOrganization;
  if (countriesErrors?.length > 0 && !isMountedRef?.current) {
    organizationCountries = [
      ...new Set([...countriesInOrganization, ...countriesErrors]),
    ];
    Notifications.showNotificationError(
      t('error'),
      t('ERROR_REMOVING_COUNTRIES'),
    );
    isMountedRef.current = true;
  }

  const [orgCountries, setOrgCountries] = useState(organizationCountries || []);
  useEffect(() => propagateChanges(orgCountries), [orgCountries]);

  const handleChanges = useCallback(country => {
    setOrgCountries(old => [
      ...(old.includes(country)
        ? [...old.filter(c => c !== country)]
        : [...old.concat(country)]),
    ]);
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: () => countries,
    setValue: newCountries => setOrgCountries(newCountries),
  }));

  return (
    <>
      <TabCountries
        {...childProps}
        countries={countries}
        organizationCountries={orgCountries}
        onChange={handleChanges}
        error={error}
      />
    </>
  );
});

TabCountriesWebHandler.propTypes = {
  t: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
  error: PropTypes.shape({
    error: PropTypes.string,
    message: PropTypes.string,
  }),
};

TabCountriesWebHandler.defaultProps = {
  error: null,
};

window.TabCountries = class TabCountriesRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'TabCountries container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <TabCountriesWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
